import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import { Button } from "@mantine/core";
import CalendarHeatmap from "../components/CalenderHeatmap";
import ThoughtsCard from "../components/ThoughtsCard";
import ActivityMetrics from "../components/ActivityMetrics";
import { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import ScrapNoteBox from "../components/ScrapNoteBox";
import TriageList from "../components/TriageList";

function Home({ setLogin }) {
  const [calendars, setCalendars] = useState([]);

  useEffect(() => {
    const fetchCalendars = async () => {
      const userId = auth.currentUser.uid;
      const calendarsCollectionRef = collection(
        db,
        "users",
        userId,
        "calendars"
      );
      const q = query(calendarsCollectionRef, where("status", "==", "active"));
      const querySnapshot = await getDocs(q);
      const fetchedCalendars = [];

      querySnapshot.forEach((doc) => {
        const calendarData = doc.data().calendar_data.map((item) => ({
          date: new Date(item.date.seconds * 1000),
          count: item.count,
          task: item.task || "",
        }));
        fetchedCalendars.push({ id: doc.id, data: calendarData });
      });

      setCalendars(fetchedCalendars);
    };

    fetchCalendars();
  }, []);

  const logout = async () => {
    try {
      await signOut(auth);
      setLogin(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100">
      <header className="w-full bg-white shadow-md p-4 flex justify-end items-center">
        <Button onClick={logout} className="bg-red-500 text-white">
          Logout
        </Button>
      </header>
      <div className="w-[90%] p-4">
        <ThoughtsCard />
      </div>
      <ActivityMetrics data={calendars.flatMap((calendar) => calendar.data)} />
      <div className="w-[90%] flex flex-row justify-between gap-2 mt-6">
        <TriageList />
        <ScrapNoteBox />
      </div>
      <main className="flex flex-col w-[90%] justify-center items-center mt-8 p-4 m-4 bg-white shadow-md rounded-lg">
        <h1 className="text-2xl font-semibold mb-4">2024 HABS Calendar</h1>
        <p className="px-8 text-sm text-gray-600">
          Effortlessly manage your daily tasks with our interactive calendar.
          Click on any date to cycle through task levels, or hover to view and
          edit details. See your year magically unfold, anytime, anywhere.
        </p>

        {calendars.map((calendar) => (
          <div key={calendar.id} className="w-full p-4">
            <CalendarHeatmap
              calendarData={calendar.data}
              calendarId={calendar.id}
            />
          </div>
        ))}

        <div className="flex justify-center items-center mt-4">
          <div className="flex items-center mr-4">
            <div className="w-4 h-4 bg-gray-300 mr-2"></div>
            <span>No Activity</span>
          </div>
          <div className="flex items-center mr-4">
            <div className="w-4 h-4 bg-[#f2be2e] mr-2"></div>
            <span>Low Activity</span>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 bg-[#76b845] mr-2"></div>
            <span>High Activity</span>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
