import React, { useState, useEffect } from "react";
import { Button, Textarea } from "@mantine/core";
import { db, auth } from "../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { startOfDay, format } from "date-fns";

function TriageList() {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState("");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const priorityOrder = {
    High: 1,
    Medium: 2,
    Low: 3,
  };

  const sortTasks = (tasks) => {
    return tasks.sort(
      (a, b) => priorityOrder[a.priority] - priorityOrder[b.priority]
    );
  };

  // Fetch tasks from Firestore when the component mounts
  useEffect(() => {
    const fetchTasks = async () => {
      setLoading(true); // Start loading
      try {
        // Simulate a delay of 1 second
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const currentUser = auth.currentUser;
        if (currentUser) {
          setUser(currentUser);
          const docRef = doc(
            db,
            "users",
            currentUser.uid,
            "tasklist",
            format(startOfDay(new Date()), "yyyy-MM-dd")
          );
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const fetchedTasks = docSnap.data().tasks || [];
            setTasks(sortTasks(fetchedTasks)); // Sort tasks before setting state
          } else {
            // Initialize with an empty list if no document exists
            await setDoc(docRef, { tasks: [] });
            setTasks([]); // Ensure state is updated
          }
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchTasks();
  }, []);

  useEffect(() => {
    if (user && tasks.length > 0) {
      const saveTasks = async () => {
        try {
          const docRef = doc(
            db,
            "users",
            user.uid,
            "tasklist",
            format(startOfDay(new Date()), "yyyy-MM-dd")
          );
          await setDoc(docRef, { tasks });
          //   console.log("Tasks saved:", tasks);
        } catch (error) {
          console.error("Error saving tasks:", error);
        }
      };
      saveTasks();
    }
  }, [tasks, user]);

  const addTask = () => {
    if (newTask.trim() === "") return;

    const updatedTasks = [...tasks, { text: newTask, priority: "Medium" }];
    setTasks(sortTasks(updatedTasks)); // Sort tasks after adding a new one
    setNewTask("");
  };

  const updateTask = (index, updatedTask) => {
    const updatedTasks = tasks.map((task, i) =>
      i === index ? updatedTask : task
    );
    setTasks(sortTasks(updatedTasks)); // Sort tasks after updating
  };

  const deleteTask = async (index) => {
    const updatedTasks = tasks.filter((_, i) => i !== index);
    setTasks(updatedTasks);

    // Save the updated tasks to Firestore immediately after deletion
    if (user) {
      try {
        const docRef = doc(
          db,
          "users",
          user.uid,
          "tasklist",
          format(startOfDay(new Date()), "yyyy-MM-dd")
        );
        await setDoc(docRef, { tasks: updatedTasks });
      } catch (error) {
        console.error("Error saving tasks after deletion:", error);
      }
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-sm w-[50%] max-w-[80%] mx-auto">
      <h2 className="text-xl font-semibold mb-4 px-2">Triage List</h2>
      <p className="text-sm text-gray-600 mb-4 px-2">
        Triage is the process of prioritizing tasks based on their urgency and
        importance. Your tasks will be refreshed daily to help you focus on
        what's most important today.
      </p>
      <div className="flex flex-col mb-4 gap-4">
        <Textarea
          className="rounded-lg p-2 w-full"
          placeholder="Add a new task..."
          value={newTask}
          onChange={(e) => setNewTask(e.target.value)}
          minRows={2}
          maxLength={60}
        />
        <Button
          className="bg-blue-500 text-white hover:bg-blue-600 rounded-lg py-1 text-sm"
          onClick={addTask}
        >
          Add Task
        </Button>
      </div>
      <div className="bg-gray-50 rounded-lg shadow-sm p-2 flex flex-col gap-2">
        {loading ? (
          // Skeleton UI while loading
          <div className="flex flex-col gap-2">
            {[...Array(3)].map((_, index) => (
              <div
                key={index}
                className="bg-white border rounded-lg shadow-sm p-2 flex items-center gap-2 w-full animate-pulse"
              >
                <div className="bg-gray-200 h-6 w-full rounded-lg"></div>
                <div className="bg-gray-200 h-8 w-20 rounded-lg"></div>
                <div className="bg-gray-200 h-8 w-20 rounded-lg"></div>
              </div>
            ))}
          </div>
        ) : tasks.length === 0 ? (
          // Empty state message
          <div className="text-center text-gray-600 py-4">
            No tasks for today. Take a deep breath and plan your priorities!
          </div>
        ) : (
          // Render tasks when available
          tasks.map((task, index) => (
            <div
              key={index}
              className="bg-white border rounded-lg shadow-sm p-2 flex items-center gap-2 w-full"
            >
              <Textarea
                className="rounded-lg p-1 flex-grow text-sm"
                value={task.text}
                onChange={(e) =>
                  updateTask(index, { ...task, text: e.target.value })
                }
                rows={1}
                maxRows={1}
                maxLength={60}
              />
              <select
                className="h-8 border-gray-300 rounded-lg text-sm"
                value={task.priority}
                onChange={(e) =>
                  updateTask(index, { ...task, priority: e.target.value })
                }
              >
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
              <Button
                className="bg-red-500 text-white hover:bg-red-600 rounded-lg py-1 text-sm"
                onClick={() => deleteTask(index)}
              >
                Delete
              </Button>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default TriageList;
