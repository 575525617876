// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  GoogleAuthProvider,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBgu-gIIdGCI51Cc9kh7Lrri3rmNjWpQFY",
  authDomain: "hhabit-45e35.firebaseapp.com",
  projectId: "hhabit-45e35",
  storageBucket: "hhabit-45e35.appspot.com",
  messagingSenderId: "663086327032",
  appId: "1:663086327032:web:3b9b0b906bf8a66a4dc70d",
  measurementId: "G-2ET9M6MYY0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

setPersistence(auth, browserSessionPersistence).catch((error) => {
  console.error("Error setting persistence: ", error);
});

const analytics = getAnalytics(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
