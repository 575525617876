import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Auth from "./pages/Auth";
import Home from "./pages/Home";
import ProtectedRoute from "./utils/ProtectedRoute";
import { auth } from "./firebase"; // Import your firebase configuration

function App() {
  const [login, setLogin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setLogin(!!user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return (
    <Router>
      <Routes>
        <Route path="/auth" element={<Auth setLogin={setLogin} />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute isAuthenticated={login}>
              <Home setLogin={setLogin} />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Navigate to={login ? "/home" : "/auth"} />} />
      </Routes>
    </Router>
  );
}

export default App;
