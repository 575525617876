import React, { useState, useEffect } from "react";
import {
  startOfYear,
  endOfYear,
  eachDayOfInterval,
  getDay,
  addDays,
  format,
} from "date-fns";
import { db, auth } from "../firebase"; // Import Firebase database
import { doc, updateDoc } from "firebase/firestore"; // Import Firestore functions
import "./CalendarHeatmap.css";
import { HoverCard, Textarea, Box, ScrollArea } from "@mantine/core";

function getWeeksInYear(data) {
  const weeks = [];
  let currentWeek = [];
  let currentWeekNumber = 0;

  data.forEach((day) => {
    if (getDay(day.date) === 1 && currentWeek.length > 0) {
      weeks.push({ weekNumber: currentWeekNumber, days: currentWeek });
      currentWeek = [];
      currentWeekNumber++;
    }
    currentWeek.push(day);
  });

  if (currentWeek.length > 0) {
    weeks.push({ weekNumber: currentWeekNumber, days: currentWeek });
  }

  return weeks;
}

function CalendarHeatmap({ calendarData, calendarId }) {
  const [data, setData] = useState(calendarData);
  const today = new Date();
  useEffect(() => {
    setData(calendarData);
  }, [calendarData]);

  const handleDayClick = async (clickedDay) => {
    const updatedData = data.map((day) =>
      day.date.getTime() === clickedDay.date.getTime() // Compare timestamps
        ? { ...day, count: (day.count + 1) % 3 }
        : day
    );
    setData(updatedData);
    const formattedData = updatedData.map((item) => ({
      date: { seconds: item.date.getTime() / 1000 }, // Convert to Firestore timestamp format
      count: item.count,
      task: item.task,
    }));
    await updateDoc(
      doc(db, "users", auth.currentUser.uid, "calendars", calendarId),
      {
        calendar_data: formattedData,
      }
    );
  };

  const handleTaskChange = async (date, task) => {
    const updatedData = data.map((day) =>
      day.date.getTime() === date.getTime() ? { ...day, task } : day
    );
    setData(updatedData);
    const formattedData = updatedData.map((item) => ({
      date: { seconds: item.date.getTime() / 1000 }, // Convert to Firestore timestamp format
      count: item.count,
      task: item.task,
    }));
    await updateDoc(
      doc(db, "users", auth.currentUser.uid, "calendars", calendarId),
      {
        calendar_data: formattedData,
      }
    );
  };

  const weeks = getWeeksInYear(data);

  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Ensure the first week starts correctly
  const firstDay = data[0]?.date;
  const startPadding = Array.from(
    { length: (getDay(firstDay) + 6) % 7 },
    (_, index) => <div key={index} className="day empty"></div>
  );

  return (
    <div className="flex flex-row">
      <div className="header flex flex-col pt-4">
        {daysOfWeek.map((day, index) => (
          <div key={index} className="day-label">
            {day}
          </div>
        ))}
      </div>
      <ScrollArea
        className="w-[100%]"
        scrollbarSize={10}
        scrollbars="x"
        offsetScrollbars
      >
        <div>
          <div className="flex flex-row">
            {months.map((month, index) => (
              <div key={index} className="month-label">
                {month}
              </div>
            ))}
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col week">
              {startPadding}
              {weeks[0]?.days.map((day, dayIndex) => (
                <HoverCard
                  key={dayIndex}
                  width={350}
                  shadow="md"
                  closeDelay={500}
                  openDelay={200}
                  position="bottom-start"
                >
                  <HoverCard.Target>
                    <div
                      className={`day ${
                        day.count === 0
                          ? "empty"
                          : day.count === 1
                          ? "low"
                          : "high"
                      } ${
                        format(day.date, "yyyy-MM-dd") ===
                        format(today, "yyyy-MM-dd")
                          ? "current"
                          : ""
                      }`}
                      title={`${format(day.date, "yyyy-MM-dd")}: ${day.count}`}
                      onClick={() => handleDayClick(day)}
                    >
                      {format(day.date, "d")}
                    </div>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    <Box>
                      <ScrollArea>
                        <Textarea
                          description={` ${format(day.date, "dd MMM yyyy")}`}
                          placeholder="Enter task"
                          autosize
                          minRows={3}
                          maxRows={4}
                          value={day.task}
                          onChange={(event) =>
                            handleTaskChange(
                              day.date,
                              event.currentTarget.value
                            )
                          }
                        />
                      </ScrollArea>
                    </Box>
                  </HoverCard.Dropdown>
                </HoverCard>
              ))}
            </div>
            {weeks.slice(1).map((week, weekIndex) => (
              <div
                key={weekIndex}
                className="

flex flex-col week"
              >
                {week.days.map((day, dayIndex) => (
                  <HoverCard
                    key={dayIndex}
                    width={350}
                    shadow="md"
                    closeDelay={500}
                    openDelay={200}
                    position="bottom-start"
                  >
                    <HoverCard.Target>
                      <div
                        className={`day ${
                          day.count === 0
                            ? "empty"
                            : day.count === 1
                            ? "low"
                            : "high"
                        } ${
                          format(day.date, "yyyy-MM-dd") ===
                          format(today, "yyyy-MM-dd")
                            ? "current"
                            : ""
                        }`}
                        title={`${format(day.date, "yyyy-MM-dd")}: ${
                          day.count
                        }`}
                        onClick={() => handleDayClick(day)}
                      >
                        {format(day.date, "d")}
                      </div>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <Box>
                        <ScrollArea>
                          <Textarea
                            description={` ${format(day.date, "dd MMM yyyy")}`}
                            placeholder="Enter task"
                            autosize
                            minRows={3}
                            maxRows={4}
                            value={day.task}
                            onChange={(event) =>
                              handleTaskChange(
                                day.date,
                                event.currentTarget.value
                              )
                            }
                          />
                        </ScrollArea>
                      </Box>
                    </HoverCard.Dropdown>
                  </HoverCard>
                ))}
              </div>
            ))}
          </div>
        </div>
      </ScrollArea>
    </div>
  );
}

export default CalendarHeatmap;
