import { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  Button,
  Input,
  Card,
  Modal,
  Group,
  Stack,
  Text,
  Box,
  ActionIcon,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { db, auth } from "../firebase";
// import { X } from "tabler-icons-react";
import { useRef } from "react";
import Autoplay from "embla-carousel-autoplay";

function ThoughtsCard() {
  const [thoughts, setThoughts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editableThoughts, setEditableThoughts] = useState([]);
  const [newThought, setNewThought] = useState("");
  const autoplay = useRef(Autoplay({ delay: 7000 }));
  const [editIndex, setEditIndex] = useState(null);

  const fetchData = async (userId) => {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const fetchedData = docSnap.data();
      setThoughts(fetchedData.thoughts || []);
      setEditableThoughts(fetchedData.thoughts || []);
    }
  };

  const updateThoughts = async (userId, updatedThoughts) => {
    const docRef = doc(db, "users", userId);
    await updateDoc(docRef, {
      thoughts: updatedThoughts,
    });
    setThoughts(updatedThoughts);
  };

  useEffect(() => {
    const userId = auth.currentUser.uid;
    fetchData(userId);
  }, []);

  const handleSaveThoughts = () => {
    const userId = auth.currentUser.uid;
    updateThoughts(userId, editableThoughts);
    setModalOpen(false);
    setEditIndex(null);
  };

  const handleAddThought = () => {
    if (newThought.trim()) {
      setEditableThoughts([...editableThoughts, newThought]);
      setNewThought("");
    }
  };

  const handleDeleteThought = (index) => {
    const updatedThoughts = editableThoughts.filter((_, i) => i !== index);
    setEditableThoughts(updatedThoughts);
  };

  return (
    <Card shadow="sm" radius="md">
      <Group justify="space-between" mb="md">
        <h2 className="text-xl font-semibold">Daily Motivational Quotes</h2>
        <div
          onClick={() => setModalOpen(true)}
          className="text-blue-500 cursor-pointer"
        >
          Add or Edit
        </div>
      </Group>
      <Carousel
        className="mb-4"
        height={150}
        loop
        withIndicators
        withControls={false}
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        styles={{
          indicator: {
            backgroundColor: "#ddd",
            "[dataactive]": {
              backgroundColor: "#000",
            },
          },
          control: {
            color: "#000",
            "[dataInactive]": {
              // Changed here
              color: "#aaa",
            },
          },
          root: {
            borderRadius: "md",
            overflow: "hidden",
            // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
          slide: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "md",
            padding: "10px",
            backgroundColor: "#f0f0f0",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        {thoughts.map((thought, index) => (
          <Carousel.Slide key={index}>
            <Text size="lg" align="center" style={{ fontWeight: 500 }}>
              {thought}
            </Text>
          </Carousel.Slide>
        ))}
      </Carousel>

      {/* Modal for adding/editing thoughts */}
      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Edit Thoughts"
        centered
        size="lg"
      >
        <Stack spacing="md">
          {editableThoughts.map((thought, index) => (
            <Group key={index} position="apart" align="center">
              <Input
                value={thought}
                onChange={(e) => {
                  const updatedThoughts = [...editableThoughts];
                  updatedThoughts[index] = e.target.value;
                  setEditableThoughts(updatedThoughts);
                }}
                className="mb-2"
                placeholder={`Thought ${index + 1}`}
                radius="md"
                style={{ flexGrow: 1 }}
              />
              <ActionIcon
                color="red"
                onClick={() => handleDeleteThought(index)}
                variant="light"
              >
                D{/* <X size={18} /> */}
              </ActionIcon>
            </Group>
          ))}
          <Box>
            <Group spacing="xs">
              <Input
                placeholder="Add a new thought"
                value={newThought}
                onChange={(e) => setNewThought(e.target.value)}
                className="mb-2"
                radius="md"
              />
              <Button
                onClick={handleAddThought}
                className="bg-green-500 text-white"
                radius="md"
              >
                Add Thought
              </Button>
            </Group>
          </Box>
          <Group position="right">
            <Button
              onClick={handleSaveThoughts}
              className="bg-blue-500 text-white"
              radius="md"
            >
              Okay
            </Button>
          </Group>
        </Stack>
      </Modal>
    </Card>
  );
}

export default ThoughtsCard;
