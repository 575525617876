import React, { useState, useEffect } from "react";
import { Textarea, Card } from "@mantine/core";
import { startOfToday } from "date-fns";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase"; // Assuming you have Firebase set up

function ScrapNoteBox() {
  const [note, setNote] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Get the current user
    const currentUser = auth.currentUser;
    setUser(currentUser);

    if (currentUser) {
      // Fetch the saved note from Firestore
      const fetchNote = async () => {
        const docRef = doc(
          db,
          "users",
          currentUser.uid,
          "notes",
          startOfToday().toISOString()
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setNote(docSnap.data().note);
        }
      };

      fetchNote();
    }
  }, []);

  const handleChange = async (event) => {
    const newNote = event.currentTarget.value;
    setNote(newNote);

    if (user) {
      const docRef = doc(
        db,
        "users",
        user.uid,
        "notes",
        startOfToday().toISOString()
      );
      await setDoc(docRef, { note: newNote }, { merge: true });
    }
  };

  return (
    <Card shadow="md" style={{ width: "50%", height: "auto" }}>
      <h2 className="text-xl font-semibold mb-4">Scrap Note</h2>
      <Textarea
        placeholder="Write your daily scrap note..."
        autosize
        minRows={10}
        maxRows={15}
        maxLength={300}
        value={note}
        onChange={handleChange}
      />
    </Card>
  );
}

export default ScrapNoteBox;
