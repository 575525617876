import React from "react";
import { Button } from "@mantine/core";
import { auth, googleProvider } from "../firebase";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function Auth({ setLogin }) {
  const navigate = useNavigate();
  console.log(auth?.currentUser?.email);

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      setLogin(true);
      navigate("/home"); // Redirect to home page
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md mx-auto my-8 text-center">
        <h1 className="text-4xl font-bold mb-4 text-blue-600">
          Welcome to Habs
        </h1>
        <p className="text-gray-700 text-lg leading-relaxed mb-6 p-4">
          Habs is your personal task management application. Start managing your
          tasks and visualizing them on a calendar heatmap.
        </p>

        <Button
          onClick={signInWithGoogle}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md"
        >
          Login with Google
        </Button>
      </div>
    </div>
  );
}

export default Auth;
