import React, { useState, useEffect } from "react";
import { Card, Text, Group, Badge, Stack } from "@mantine/core";

const ActivityMetrics = ({ data }) => {
  const [streak, setStreak] = useState(0);
  const [overallScore, setOverallScore] = useState(0);

  useEffect(() => {
    // Calculate Streak
    let maxStreak = 0;
    let currentStreak = 0;
    data.sort((a, b) => b.date - a.date); // Sort data by date descending

    data.forEach((item, index) => {
      if (item.count > 0) {
        currentStreak += 1;
        maxStreak = Math.max(maxStreak, currentStreak);
      } else {
        currentStreak = 0;
      }
    });
    setStreak(maxStreak);

    // Calculate Overall Score
    const totalScore = data.reduce((acc, item) => acc + item.count, 0);
    const averageScore = data.length ? totalScore / data.length : 0;
    setOverallScore(averageScore);
  }, [data]);

  return (
    <Card shadow="sm" radius="md" padding="lg">
      <Text size="lg" weight={500}>
        Activity Metrics
      </Text>
      <Stack spacing="md">
        <Group position="apart">
          <Text size="md">Streak:</Text>
          <Badge color="teal" variant="light">
            {streak} days
          </Badge>
        </Group>
        <Group position="apart">
          <Text size="md">Overall Score:</Text>
          <Badge color="blue" variant="light">
            {overallScore.toFixed(2)}
          </Badge>
        </Group>
      </Stack>
    </Card>
  );
};

export default ActivityMetrics;
